* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Roboto';
    src: url("./font/Roboto-Medium.ttf");
}

@font-face {
    font-family: 'Manrope-Regular';
    src: url("./font/Manrope-Regular.ttf");
}

@font-face {
    font-family: 'Manrope-ExtraBold';
    src: url("./font/Manrope-ExtraBold.ttf");
}

body {
    font-size: 18px;
    font-family: 'Manrope-Regular';
    font-weight: 400;
}

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
}

.container {
    max-width: 1200px;
    width: calc(100% - 30px);
    padding: 0 15px 0 15px;
}

h1 {
    color: #000000;
    font-size: 40px;
    font-family: 'Manrope-ExtraBold';
    font-weight: 800;
}

h2 {
    color: #000000;
    font-size: 24px;
}

.ant-collapse-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ant-collapse-content {
    background-color : transparent !important;
}

@media (max-width: 550px) {
    .main-header {
        text-align: start;
        font-size: 30px;
    }
}

@media (max-width: 360px) {
    h1,h2 {
        font-size: 20px;
    }
}
