
.col-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}


@media (max-width: 576px) {
    .col-bg-color {
        background-color: #bdcdda;
        width: 100%;
    }
}
