
canvas {
    width: 100% !important;
    max-width: 500px;
    height: auto !important;
    border: 1px solid #000;
    margin-bottom: 40px;
}

#layout {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /*min-height: 100vh;*/
    background-color: #fff;
    z-index: 999999;
}

#certificate-v1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1999px;
    height: 1412px;
    background-image: url("./bg.svg");
    padding: 60px;
}

#certificate-v1 .wrapper-certificate {
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.85);
    border: 15px solid #4774E2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#certificate-v1 .title {
    margin-top: 188px;
    font-size: 50px;
}

#certificate-v1 .name {
    font-family: Manrope-ExtraBold;
    font-weight: 800;
    margin-top: 17px;
    font-size: 90px;
    color: #4774E2;
}

#certificate-v1 .description {
    max-width: 1500px;
    text-align: center;
    font-size: 42px;
    margin-top: 25px;
}

#certificate-v1 .statistic-block {
    width: calc(100% - 446px);
    margin-top: 100px;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#certificate-v1 .statistic-item {
    width: 210px;
    text-align: center;
}

#certificate-v1 .statistic-value {
    font-family: Manrope-ExtraBold;
    font-weight: 700;
    font-size: 80px;
    color: #4774E2;
    margin-bottom: 12px;
}

#certificate-v1 .footer-block {
    width: calc(100% - 446px);
    margin-top: 110px;
    font-size: 21px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

#certificate-v1 .info-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#certificate-v1 .info-value {
    font-family: Manrope-ExtraBold;
    font-weight: 700;
    font-size: 30px;
}

#certificate-v1 .info-description {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 385px;
}

#certificate-v1 .line {
    width: 100%;
    min-height: 1px;
    background-color: #000;
}

#certificate-v1 .level {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 42px;
    background-color: #4774E2;
    height: 108px;
    width: 350px;
    text-align: center;
    border-radius: 50px;
}
